import React, { useState, useEffect } from "react";
import CarousalLeft from "../../SVGs/CarousalLeft";
import CarousalRight from "../../SVGs/CarousalRight";
import UpdateCard from "../UpdateCard.tsx";
import "./styles.scss";

interface UpdatesProps {
  updatesTitle: string;
  updatesSubTitle: string;
  updatesCards: {
    id: number;
    title: string;
    timeStamp: number;
    date: string;
    description: string;
    image: {
      id: number;
      src: string;
    }[];
  }[];
}

export default function Updates({
  updatesTitle,
  updatesSubTitle,
  updatesCards,
}: UpdatesProps) {
  const [hideLeftArrow, setHideLeftArrow] = useState(true);
  const [hideRightArrow, setHideRightArrow] = useState(false);

  useEffect(() => {
    const carousalElement = document.getElementById("updatesCardsContainer");

    if (carousalElement) {
      updateArrowVisibility();
      carousalElement.addEventListener("scroll", updateArrowVisibility);
    }

    window.addEventListener("resize", updateArrowVisibility);

    return () => {
      if (carousalElement) {
        carousalElement.removeEventListener("scroll", updateArrowVisibility);
      }
      window.removeEventListener("resize", updateArrowVisibility);
    };
  }, []);

  const updateArrowVisibility = () => {
    const carousalElement = document.getElementById("updatesCardsContainer");

    if (carousalElement) {
      setHideLeftArrow(carousalElement.scrollLeft === 0);
      setHideRightArrow(
        carousalElement.scrollLeft + carousalElement.offsetWidth >=
          carousalElement.scrollWidth
      );
    }
  };

  const handleCarousalScrollCards = (direction: string = "left") => {
    const carousalElement = document.getElementById("updatesCardsContainer");

    if (carousalElement) {
      let scrollAmount = 465;

      if (window.innerWidth >= 1280) {
        scrollAmount = 360;
      }

      if (window.innerWidth >= 1440) {
        scrollAmount = 413.33;
      }

      if (window.innerWidth >= 1600) {
        scrollAmount = 470;
      }

      const adjustedScrollAmount =
        direction === "left" ? -scrollAmount : scrollAmount;

      carousalElement.scrollBy({
        left: adjustedScrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="updates-outer-container">
      <div className="updates-container">
        <div className="updates-title">{updatesTitle}</div>
        <div className="updates-sub-title">{updatesSubTitle}</div>

        <div
          className="updates-cards-container"
          style={{
            display: "flex",
            justifyContent: updatesCards.length < 2 ? "center" : undefined,
            ...(updatesCards.length < 2 && { overflow: "hidden" }),
          }}
          id="updatesCardsContainer"
        >
          <div className="buttons-container">
            {updatesCards.length > 3 && (
              <>
                {!hideLeftArrow && (
                  <button
                    className="submenu-carousel-left"
                    onClick={() => handleCarousalScrollCards("left")}
                    aria-label="Left"
                  >
                    <CarousalLeft />
                  </button>
                )}

                {!hideRightArrow && (
                  <button
                    className="submenu-carousel-right"
                    onClick={() => handleCarousalScrollCards("right")}
                    aria-label="Right"
                  >
                    <CarousalRight />
                  </button>
                )}
              </>
            )}
          </div>
          <div
            className="updates-cards"
            style={{
              justifyContent: updatesCards.length < 3 ? "center" : undefined,
            }}
          >
            {updatesCards?.map((uc) => (
              <UpdateCard key={uc.title} {...uc} />
            ))}
            {/* {updatesCards?.length > 0 && (
              <UpdateCard key={updatesCards[0].title} {...updatesCards[0]} />
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}
