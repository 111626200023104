import { createClient } from "contentful";

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE || "",
  environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT || "master",
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN || "",
});

export const fetchContentfulData = async () => {
  // Check for "site_id" in the URL parameters
  const urlParams = new URLSearchParams(window.location.search);
  const urlSiteId = urlParams.get("site_id");
  const envSiteId = process.env.REACT_APP_CONTENTFUL_SITE_ID;
  const siteId = urlSiteId || envSiteId;

  const params = {
    content_type: process.env.REACT_APP_CONTENTFUL_CONTENT_TYPE as string,
    "fields.id": siteId,
  };

  try {
    // Initial request using the site ID from the URL or .env as fallback
    let res = await client.getEntries(params);

    // If URL site_id was used and returned empty, retry with env SITE_ID
    if (urlSiteId && res.items.length === 0) {
      console.log("Retrying with fallback SITE_ID from .env...", envSiteId);
      params["fields.id"] = envSiteId;
      res = await client.getEntries(params);
    }

    return res.items[0]?.fields ?? {};
  } catch (error) {
    console.error("Error fetching Contentful data:", error);
    return {};
  }
};
