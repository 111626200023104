import { fetchContenfulImage } from "../../utils";
import "./styles.scss";

interface HeroSectionProps {
  headerText: string;
  contactUsText: string;
  titleText: string;
  heroImage: { src: string }[];
}

export default function HeroSection({
  headerText,
  contactUsText,
  titleText,
  heroImage,
}: HeroSectionProps) {
  const handleClick = () => {
    const el = document.getElementById("contactUsContainer");
    if (el) {
      el.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  return (
    <div className="hero-container">
      <div
        className="image-container"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${fetchContenfulImage(
            heroImage[0]
          )})`,
        }}
      >
        <div className="header">
          <div className="header-text">{headerText}</div>
          <div className="contact-us" onClick={handleClick}>
            {contactUsText}
          </div>
        </div>

        {/* <div className="title">{titleText}</div> */}
      </div>
    </div>
  );
}
