import Slider from "react-slick";
import "./styles.scss";
import CarousalRight from "../../SVGs/CarousalRight";
import CarousalLeft from "../../SVGs/CarousalLeft";
import { useState } from "react";
import { fetchContenfulImage } from "../../utils";

interface ProjectHighlightsProps {
  projectHighlightTitle: string;
  imagesCarousel: {
    id: number;
    image: {
      src: string;
    }[];
    title: string;
    description: string;
  }[];
}

const ProjectHighlights = ({
  projectHighlightTitle,
  imagesCarousel,
}: ProjectHighlightsProps) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const CarousalNextButton = ({ className, onClick }: any) => {
    return (
      <div className={`${className}`} onClick={onClick} tabIndex={0}>
        <CarousalRight className={`${className}`} />
      </div>
    );
  };

  const CarousalLeftButton = ({ className, onClick }: any) => {
    return (
      <div onClick={onClick} className={`${className}`} tabIndex={0}>
        <CarousalLeft className={`${className}`} />
      </div>
    );
  };

  const hasMultipleImages = imagesCarousel?.length > 1;
  const settings = {
    dots: true,
    infinite: hasMultipleImages,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
    // autoplay: hasMultipleImages,
    nextArrow: hasMultipleImages ? <CarousalNextButton /> : undefined,
    prevArrow: hasMultipleImages ? <CarousalLeftButton /> : undefined,
    afterChange: (current: number) => setActiveSlide(current),
  };

  return (
    <div className="projects-outer-container">
      <div className="projects-container">
        <div className="project-highlights-text">{projectHighlightTitle}</div>
        <section className="office-gallery-carousal-section" id="AboutUs">
          <section
            className={`${
              hasMultipleImages
                ? "office-image-carousal"
                : "office-single-carousal"
            }`}
          >
            <div className="carousal-slider">
              <Slider {...settings}>
                {imagesCarousel?.map((carousalItem, index) => (
                  <div
                    className="office-image-container"
                    key={carousalItem?.title}
                  >
                    <img
                      src={fetchContenfulImage(carousalItem?.image[0])}
                      alt={carousalItem?.title ?? ""}
                      className="office-item-image"
                    />
                    {index === activeSlide && carousalItem?.description && (
                      <div className="image-view-text">
                        {carousalItem.description}
                      </div>
                    )}
                  </div>
                ))}
              </Slider>
            </div>
          </section>
        </section>
      </div>
    </div>
  );
};

export default ProjectHighlights;
