import { useEffect, useState } from "react";
import "./App.css";
import ContactUsSection from "./components/ContactUsSection";
import Description from "./components/Description";
import HeroSection from "./components/Hero";
import ProjectHighlights from "./components/ProjectHighlights";
import Updates from "./components/Updates";
import { fetchContentfulData } from "./lib/contentful";

function App() {
  const [siteData, setSiteData] = useState<null | any>(null);

  useEffect(() => {
    const fetchLoadData = async () => {
      try {
        const res = await fetchContentfulData();
        setSiteData(res);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchLoadData();
  }, []);

  return (
    <main className="App">
      {siteData && (
        <>
          <HeroSection
            headerText={siteData?.name ?? ""}
            heroImage={siteData?.heroImage ?? ""}
            titleText={siteData?.heroText ?? ""}
            contactUsText={siteData?.contactUsSectionTitle ?? ""}
          />
          <Description siteDescription={siteData?.siteDescription ?? ""} />
          <ProjectHighlights
            projectHighlightTitle={
              siteData?.projectHighlightTitle ?? "Project Main Highlights"
            }
            imagesCarousel={
              siteData?.highlights?.map((i: any) => i.fields) ?? []
            }
          />
          <Updates
            updatesTitle={siteData?.updateSectionTitle ?? "Updates"}
            updatesSubTitle={siteData?.updatesSectionDescription ?? ""}
            updatesCards={
              siteData?.updates
                ?.map((i: any) => i.fields)
                .sort(
                  (a: any, b: any) =>
                    new Date(b.date).getTime() - new Date(a.date).getTime()
                ) ?? []
            }
          />
          <ContactUsSection
            description={siteData?.contactUsDescription ?? ""}
            addresses={
              siteData?.contactDetails?.map((i: any) => i.fields) ?? []
            }
            contactUsImage={siteData?.contactUsImage ?? []}
          />
        </>
      )}
    </main>
  );
}

export default App;
