import { useState, useEffect } from "react";
import "./styles.scss";
import Slider from "react-slick";
import ModalPortal from "../ModalPortal";
import ModalContent from "../ModalContent";
import { fetchContenfulImage } from "../../utils";

interface UpdateCardProps {
  id: number;
  timeStamp: number;
  date: string;
  description: string;
  image: {
    id: number;
    src: string;
  }[];
}

export default function UpdateCard({
  image,
  date,
  description,
}: UpdateCardProps) {
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    // Toggle body scroll when modal is open or closed
    if (openModal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Clean up when component is unmounted
    return () => document.body.classList.remove("no-scroll");
  }, [openModal]);

  const formattedDate = new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const settings = {
    dots: image?.length > 1 ? true : false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
    autoplay: true,
  };

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  return (
    <div className="card-container">
      <div className="image-carousel">
        <Slider {...settings}>
          {image?.map((img) => (
            <div className="office-image-container" key={img.id}>
              <img
                className="office-item-image"
                src={fetchContenfulImage(img)}
                alt="office"
              />
            </div>
          ))}
        </Slider>
      </div>

      <div className="card-content">
        <div className="date">{formattedDate}</div>
        <div className="description">{description}</div>

        {description?.length > 170 && (
          <div className="read-more" onClick={handleOpen}>
            Read More...
          </div>
        )}
      </div>

      {openModal && (
        <ModalPortal>
          <ModalContent
            formattedDate={formattedDate}
            settings={settings}
            image={image}
            description={description}
            handleClose={handleClose}
          />
        </ModalPortal>
      )}
    </div>
  );
}
