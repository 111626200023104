import { Box } from "@mui/material";
import "./styles.scss";
import Slider from "react-slick";
import { fetchContenfulImage } from "../../utils";

interface ModalContentProps {
  formattedDate: string;
  settings: any;
  image: { id: number; src: string }[];
  description: string;
  handleClose: () => void;
}

export default function ModalContent({
  formattedDate,
  settings,
  image,
  description,
  handleClose,
}: ModalContentProps) {
  return (
    <div onClick={handleClose} className="modal-wrapper">
      <Box
        onClick={(e) => e.stopPropagation()}
        className="modal-content"
        sx={{
          bgcolor: "background.paper",
          borderRadius: 2,
        }}
      >
        <div className="image-carousel">
          <Slider {...settings} className="carousal-slider">
            {image.map((img) => (
              <div className="office-image-container" key={img.id}>
                <img
                  className="office-item-image"
                  src={fetchContenfulImage(img)}
                  alt="update-card-image"
                />
              </div>
            ))}
          </Slider>
        </div>

        <div className="content-description">
          <p className="date">{formattedDate}</p>
          <p className="description">{description}</p>
          <div className="close-button" onClick={handleClose}>
            Close
          </div>
        </div>
      </Box>
    </div>
  );
}
