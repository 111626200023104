export const getDimensionsBy30Percent = (dimension: number, type?: string) => {
  const dimesionBy30 =
    type !== "width"
      ? dimension + dimension * 0.3
      : dimension + dimension * 0.5;
  return Math.ceil(dimesionBy30);
};

export const fetchContenfulImage = (
  data: any,
  height?: number,
  width?: number
) => {
  let src: string = "";
  // let imageQuality = 75;

  if (data?.selectedFile?.url) {
    src = data?.selectedFile?.url;
  } else if (data?.src) {
    src = data.src;
  } else if (data && data[0]?.selectedFile?.url) {
    src = data[0]?.selectedFile?.url;
  } else if (data && data[0]?.src) {
    src = data[0].src;
  } else {
    src = data;
  }

  // if (height && width) {
  if (!src.includes("/transform/")) {
    let transformURL = data?.thumbnails?.transformBaseUrl;
    src = transformURL?.includes("/transform/") ? transformURL : src;
  }

  if (src?.includes("/transform/")) {
    let urlSplit = src.split("?");
    let params: any = {};
    if (urlSplit[1]) {
      let queryParamsArr = urlSplit[1].split("&");

      if (queryParamsArr) {
        queryParamsArr.forEach(function (keyValue) {
          let pair = keyValue.split("=");
          params[pair[0]] = pair[1];
        });

        /**
         * If urlParams already has `io`, then check for transform, height and width
         * If not available then add, else ignore- don't override
         */
        if (params["io"] && height && width) {
          if (!params["io"].includes("transform")) {
            params["io"] += ",transform:fill";
          }
          if (
            !params["io"].includes("width") &&
            !params["io"].includes("height")
          ) {
            if (
              params["io"].includes("transform:fill") ||
              params["io"].includes("transform:fit")
            ) {
              params["io"] += `,width:${getDimensionsBy30Percent(
                width,
                "width"
              )},height:${getDimensionsBy30Percent(height)}`;
            }
          }
        }

        // if (!params["quality"]) {
        //   params["quality"] = imageQuality;
        // }

        let updatedParams = Object.keys(params)
          .map(function (key) {
            return key + "=" + params[key];
          })
          .join("&");

        src = urlSplit[0] + "?" + updatedParams;
      } else {
        src +=
          height && width
            ? `?io=transform:fill,width:${getDimensionsBy30Percent(
                width,
                "width"
              )},height:${getDimensionsBy30Percent(height)}`
            : ``;
      }
    } else {
      src +=
        height && width
          ? `?io=transform:fill,width:${getDimensionsBy30Percent(
              width,
              "width"
            )},height:${getDimensionsBy30Percent(height)}`
          : ``;
    }
  }
  // }

  return src;
};
