const MailIcon = () => {
  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_147_639)">
        <path
          d="M5.99684 0.000709852C7.40284 0.000709852 8.80884 -0.00171763 10.2148 0.00168084C11.0819 0.00362283 11.781 0.569225 11.9602 1.40962C11.9859 1.53099 11.9976 1.65771 11.9976 1.78199C12 3.53075 12.001 5.27951 11.9985 7.02826C11.9971 8.03712 11.2213 8.82654 10.2148 8.82848C7.39896 8.83334 4.5826 8.83334 1.76623 8.82848C0.766113 8.82702 0.00242748 8.04877 0.00145649 7.04477C-0.000485496 5.29213 -0.000485496 3.539 0.00145649 1.78636C0.00242748 0.777018 0.769025 0.00507931 1.77886 0.00168084C3.18485 -0.00268862 4.59085 0.000709852 5.99684 0.000709852ZM5.98908 8.17743C7.39119 8.17743 8.7933 8.17889 10.1954 8.17694C10.8732 8.17597 11.346 7.70213 11.3465 7.02438C11.348 5.28388 11.348 3.54289 11.3465 1.80238C11.346 1.13094 10.8732 0.659042 10.2032 0.654187C9.93664 0.652245 9.66962 0.654187 9.40308 0.654187C6.87802 0.654187 4.35247 0.654187 1.82741 0.654187C1.11664 0.654187 0.653963 1.11929 0.653963 1.83151C0.653963 3.556 0.652992 5.28048 0.655419 7.00544C0.655419 7.12148 0.664158 7.24091 0.692317 7.35306C0.820002 7.857 1.25549 8.17743 1.80799 8.1784C3.20185 8.17986 4.5957 8.1784 5.99005 8.1784L5.98908 8.17743Z"
          fill="black"
        />
        <path
          d="M4.14727 4.84088C3.89675 5.08605 3.654 5.322 3.41223 5.55941C3.0146 5.94975 2.6165 6.34009 2.21985 6.7314C2.08925 6.86054 1.9402 6.87316 1.8232 6.76247C1.70959 6.6542 1.7159 6.48185 1.84407 6.35465C2.44075 5.76283 3.03791 5.17247 3.63652 4.58259C3.67051 4.5491 3.71469 4.52628 3.76664 4.48986C3.44038 4.19905 3.14035 3.93591 2.84468 3.66743C2.42958 3.28972 2.0174 2.9086 1.60521 2.52749C1.4581 2.39155 1.43626 2.19493 1.54938 2.07549C1.68046 1.93713 1.84553 1.94053 2.00963 2.08812C3.00441 2.98143 3.99822 3.87523 4.99251 4.76903C5.5989 5.31424 6.37666 5.31569 6.98402 4.77048C7.97589 3.88008 8.9663 2.98871 9.95768 2.09734C10.0849 1.98276 10.2184 1.95509 10.3446 2.01626C10.4446 2.06481 10.5209 2.18959 10.4927 2.30805C10.4733 2.38864 10.4262 2.47554 10.3655 2.53137C9.74455 3.1028 9.11777 3.66792 8.49294 4.23498C8.40943 4.31072 8.3269 4.38743 8.23708 4.47044C8.27252 4.50783 8.30068 4.53987 8.33127 4.56997C8.9226 5.15548 9.51491 5.74002 10.1048 6.3265C10.1524 6.37359 10.1995 6.42942 10.2237 6.49059C10.2669 6.59837 10.2378 6.6979 10.1431 6.77072C10.047 6.84452 9.94311 6.85617 9.83922 6.78966C9.79213 6.75956 9.75183 6.71829 9.71153 6.67897C9.10563 6.0852 8.50071 5.49096 7.89529 4.8972C7.8783 4.88069 7.85888 4.86612 7.82684 4.83942C7.69139 4.95934 7.55642 5.07877 7.42145 5.1982C7.13695 5.45115 6.82235 5.65311 6.44463 5.73176C5.79018 5.8677 5.19448 5.74487 4.6784 5.30404C4.50362 5.15451 4.33127 5.00206 4.14678 4.84088H4.14727Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_147_639">
          <rect width="12" height="8.83263" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MailIcon;
