import LocationIcon from "../../SVGs/LoactionIcon";
import MailIcon from "../../SVGs/MailIcon";
import PhoneIcon from "../../SVGs/PhoneIcon";
import { fetchContenfulImage } from "../../utils";
import "./styles.scss";

interface ContactUsSectionProps {
  description: string;
  addresses: {
    name: string;
    title: string;
    phone: string;
    email: string;
    address: string;
  }[];
  contactUsImage: {
    src: string;
    id: string;
    name: string;
  }[];
}

export default function ContactUsSection({
  description,
  addresses,
  contactUsImage,
}: ContactUsSectionProps) {
  return (
    <div className="contact-us-container" id="contactUsContainer">
      <div className="contact-us-inner-container">
        <div className="image-section">
          <img
            className="contact-us-image"
            src={fetchContenfulImage(contactUsImage[0])}
            alt={contactUsImage[0]?.name ?? ""}
          />
        </div>

        <div className="contact-us-section">
          <div className="contact-us-heading">
            <div className="title">Contact Us</div>
            <div className="description">{description}</div>
          </div>

          <div className="addresses">
            {addresses?.map((add) => (
              <div className="address" key={add.name}>
                <div className="heading">{add.name}</div>
                <div className="contact-items">
                  <a className="contact-item" href={`tel:+${add.phone}`}>
                    <PhoneIcon /> &nbsp; {add.phone}
                  </a>
                  <a className="contact-item" href={`mailto:${add.email}`}>
                    <MailIcon />
                    &nbsp; {add.email}
                  </a>
                  {/* <p className="contact-item">
                    <LocationIcon />
                    &nbsp; {add.address}
                  </p> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
