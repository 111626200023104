import "./styles.scss";

export default function Description({
  siteDescription,
}: {
  siteDescription: string;
}) {
  return (
    <div className="description-container">
      <p className="description">{siteDescription}</p>
    </div>
  );
}
