const CarousalRight = ({ className }: any) => {
  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_162_1386)">
        <ellipse cx="33" cy="30" rx="30" ry="30" fill="white" />
        <path
          d="M28.8022 19.0536L39.5435 29.8195L28.8022 40.5853C28.6099 40.7776 28.5022 41.0359 28.5022 41.3047C28.5022 41.5736 28.6099 41.8318 28.8022 42.0242C28.8956 42.1173 29.007 42.1913 29.1301 42.2418C29.2531 42.2923 29.3852 42.3184 29.5187 42.3184C29.6521 42.3184 29.7843 42.2923 29.9073 42.2418C30.0303 42.1913 30.1418 42.1173 30.2352 42.0242L41.6644 30.5712C41.8651 30.3701 41.9774 30.1003 41.9774 29.8195C41.9774 29.5386 41.8651 29.2688 41.6644 29.0677L30.2374 17.6147C30.144 17.5209 30.0322 17.4464 29.9087 17.3955C29.7853 17.3446 29.6527 17.3184 29.5187 17.3184C29.3847 17.3184 29.2521 17.3446 29.1286 17.3955C29.0052 17.4464 28.8934 17.5209 28.8 17.6147C28.6077 17.8071 28.5 18.0653 28.5 18.3342C28.5 18.6031 28.6077 18.8613 28.8 19.0536L28.8022 19.0536Z"
          fill="#24272C"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_162_1386"
          x="0"
          y="0"
          width="68"
          height="68"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_162_1386"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_162_1386"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default CarousalRight;
