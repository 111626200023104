import ReactDOM from "react-dom";
import { ReactNode } from "react";

interface ModalPortalProps {
  children: ReactNode;
}

export default function ModalPortal({ children }: ModalPortalProps) {
  const modalRoot = document.getElementById("modal-root");

  return modalRoot ? ReactDOM.createPortal(children, modalRoot) : null;
}
